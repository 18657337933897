// Routes
import authenticationRoutes from './authentication.js'
import signedInPagesRoutes from './signedInPages.js'
import prescreenRoutes from './prescreen.js'
import surveyRoutes from './survey.js'
import dashboardRoutes from './dashboard.js'
import viewsRoutes from './views.js'
import { i18n } from '@/plugins/i18n.js'

// Main flow
const TheMain = () => import('@/components/views/Main/TheMain.vue')
const Home = () => import('@/components/views/HomeV3/HomeV3.vue')
const Legal = () => import('@/components/views/Main/LegalV3/LegalV3.vue')
const Direct = () => import('@/components/views/Main/Direct/Direct.vue')
const Prescreen = () => import('@/components/views/Main/Prescreen/PrescreenV3.vue')
const PrescreenConsent = () =>
  import('@/components/views/Main/PrescreenConsent/PrescreenConsentV3.vue')
const FAQ = () => import('@/components/views/Main/FAQ/FAQ.vue')
const GuardianConsent = () => import('@/components/views/GuardianConsent/GuardianConsent.vue')
const SignedOutResult = () => import('@/components/views/Results/views/SignedOutResult.vue')
const NotificationPreferences = () =>
  import('@/components/views/NotificationPreferences/NotificationPreferences.vue')

export default {
  path: '',
  component: TheMain,
  name: 'TheMain',
  children: [
    ...authenticationRoutes,
    ...signedInPagesRoutes,
    ...prescreenRoutes,
    ...viewsRoutes,
    { ...surveyRoutes },
    { ...dashboardRoutes },
    {
      path: 'home',
      name: 'Home',
      component: Home,
      meta: {
        title: i18n.t('headful_title.home'),
        redirectOnAuthentication: true
      }
    },
    {
      path: 'legal/:productId?',
      name: 'Legal',
      component: Legal,
      props: true,
      meta: {
        title: i18n.t('headful_title.terms_and_conditions')
      }
    },
    {
      path: 'direct/:orgId/:productId',
      name: 'Direct',
      component: Direct,
      props: true,
      meta: {
        title: i18n.t('headful_title.direct')
      }
    },
    {
      path: 'direct-prescreen/:accessCode/:orgId/:productId',
      name: 'DirectPrescreen',
      component: Prescreen,
      props: true,
      meta: {
        title: i18n.t('headful_title.prescreen'),
        isFooterHidden: true,
        showOrderWizardHeader: true
      }
    },
    {
      path: 'direct-prescreen-consent/:accessCode/:orgId/:productId',
      name: 'DirectPrescreenConsent',
      component: PrescreenConsent,
      props: true,
      meta: {
        title: i18n.t('headful_title.prescreen_consent'),
        showOrderWizardHeader: true,
        isFooterHidden: true
      }
    },
    {
      path: 'faq',
      name: 'FAQ',
      component: FAQ,
      meta: {
        title: i18n.t('headful_title.faq')
      }
    },
    {
      path: 'consent/:uuid',
      name: 'GuardianConsent',
      component: GuardianConsent,
      meta: {
        title: i18n.t('headful_title.guardian_consent')
      }
    },
    {
      path: 'external-results/:uuid/',
      name: 'ExternalResults',
      component: SignedOutResult,
      props: true,
      meta: {
        title: i18n.t('headful_title.signed_out_result')
      }
    },
    {
      path: 'notification-preferences',
      name: 'NotificationPreferences',
      component: NotificationPreferences,
      meta: {
        title: i18n.t('headful_title.notification_preferences')
      }
    }
  ]
}
