'use strict'

import axios from 'axios'
import { postMultiPartFormData, save } from '@/utils/networkUtils.js'

// ??? can we use these in an interceptor?
// TODO: add the list response mapping
export const getResponse = (resp) => resp.data
export const getErrorResponse = (error) => {
  throw error?.response?.data || error
}

// axios config (Full config:  https://github.com/axios/axios#request-config)
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.baseURL = '/api'
axios.defaults.headers.common['X-Ixlayer-Portal'] = 'patient'
axios.defaults.withCredentials = true

/**
 * Api class
 */
export default class IXLayerAPI {
  /**
   * Register
   * @param data
   * @param params
   * @returns {Promise<any>}
   */
  static register(data, params = {}) {
    return axios.post('/auth/register/', data, { params })
  }

  /**
   * Login a user
   * @param data
   * @returns {Promise<any>}
   */
  static login(data) {
    return axios.post('/auth/login/', data)
  }

  /**
   * Logout a user
   */
  static logout() {
    return axios.post('/auth/logout/')
  }

  /**
   * Send a forgot password link
   * @param data
   * @returns {AxiosPromise<any>}
   */
  static forgotPassword(data) {
    return axios.post('/auth/reset-password/', data)
  }

  /**
   * Verify email using an activation key
   * @param activationKey
   * @returns {AxiosPromise<any>}
   */
  static checkVerificationHash(activationKey) {
    return axios.get(`/auth/verify-email/${activationKey}/`)
  }

  /**
   * Verify email using an activation key
   * @param activationKey
   * @returns {AxiosPromise<any>}
   */
  static verifyEmail(activationKey) {
    return axios.post(`/auth/verify-email/${activationKey}/`)
  }

  /**
   * Resend Activation Email
   * @param data
   * @param params
   * @returns {AxiosPromise<any>}
   */
  static resendActivation(data, params = {}) {
    return axios.post('/auth/resend-activation/', data, { params })
  }

  /**
   * Change password
   * @param data
   * @returns {AxiosPromise<any>}
   */
  static changePassword(data) {
    return axios.post('/auth/change-password/', data)
  }

  static checkPersonalCodeEligibility(personalCode, dob) {
    const queryParams = {
      eligibility_access_code: personalCode,
      date_of_birth: dob
    }
    return axios.get('/check_eligibility/', { params: queryParams })
  }

  static verifyEligibilityEmail(personalCode, dob, email) {
    const params = {
      eligibility_access_code: personalCode,
      date_of_birth: dob
    }
    return axios.post('/eligibility_verify_email/', { email }, { params })
  }

  /**
   * Get Questionnaire
   * @param name
   * @returns {AxiosPromise<any>}
   */
  static getQuestionnaire(name) {
    return axios.get(`questionnaire/${name}/`)
  }

  /**
   * Send Questionnaire Responses
   * @param name
   * @param data
   * @returns {AxiosPromise<any>}
   */
  static sendQuestionnaireResponses(name, data) {
    return axios.post(`questionnaire/${name}/submit-response/`, data)
  }

  /**
   * Send PreScreen Questionnaire Responses and basic profile data
   * @param data
   * @returns {AxiosPromise<any>}
   */
  static sendPreScreenQuestionnaireResponses(data) {
    return axios.post('/auth/register_questionnaire/', data)
  }

  /**
   * Allows to create multiple product statuses with a consent and questionnaire response with a single request
   *
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  static sendMultiProductOrder(data) {
    return axios.post('/multiproductorder/', data)
  }

  static getSampleCollection(productId, locale) {
    return axios.get(`/product/${productId}/sample_collection/`, { params: { locale } })
  }

  /**
   * Get the user's profile
   * @param params
   * @returns {AxiosPromise<any>}
   */
  static getMyProfile(params) {
    return axios.get('me/', { params })
  }

  /**
   * Update the user's profile
   * @param data
   * @param omit
   * @returns {AxiosPromise<any>}
   */
  static updateMyProfile(data, omit) {
    const params = { omit }
    return axios.put('me/', data, { params })
  }

  /**
   * Complemente the user's profile
   * @param data
   * @param omit
   * @returns {AxiosPromise<any>}
   */
  static patchMyProfile(data, omit) {
    const params = { omit }
    return axios.patch('me/', data, { params })
  }

  /**
   * Health Care Providers
   */

  static getHealthCareProviders(params) {
    return axios.get('me/healthcare-providers/', { params })
  }

  static createHealthCareProvider(data) {
    return axios.post('me/healthcare-providers/', data)
  }

  static updateHealthCareProviderById(id, data) {
    return axios.put(`me/healthcare-providers/${id}/`, data)
  }

  static patchHealthCareProviderById(id, data) {
    return axios.patch(`me/healthcare-providers/${id}/`, data)
  }

  static deleteHealthCareProviderById(id) {
    return axios.delete(`me/healthcare-providers/${id}/`)
  }

  static agreeConsent(data) {
    return axios.post('api/me/consent/', data)
  }

  static getNotificationPreferences(params) {
    return axios.get('me/notification_preferences/', { params })
  }

  static updateNotificationPreferences(data, params) {
    return axios.put('me/notification_preferences/', data, { params })
  }

  /**
   * Delete the user's insurance
   * @param data
   * @returns {AxiosPromise<any>}
   */
  static deleteUserInsurance({ insuranceId }) {
    return axios.delete(`me/insurance/info/${insuranceId}/`)
  }

  /**
   * Create or update the user's insurance. Take care of POST or PUT accordingly
   * @param data
   * @returns {AxiosPromise<any>}
   */
  static saveUserInsurance(data) {
    return this._saveInsurance('me', data)
  }

  /**
   * Create or update the order's insurance. Take care of POST or PUT accordingly
   * @param productStatusId - the id of the ongoing product status (order)
   * @param data
   * @returns {AxiosPromise<any>}
   */
  static saveOrderInsurance(productStatusId, data) {
    return this._saveInsurance(`productstatus/${productStatusId}`, data)
  }

  static _saveInsurance(prefix, data) {
    return save(`${prefix}/insurance/info/`, data).then(getResponse).catch(getErrorResponse)
  }

  /**
   * Upload a document to the user's insurance
   * @param insuranceId - which insurance the documentum belongs to
   * @param documentType - can be "front" or "back" (image of the insurance card)
   * @param file - File to upload
   * @param progressCallback - The progress of the File upload. (percentage) => {...}
   * @returns {AxiosPromise<any>}
   */
  static uploadUserInsuranceDocument({ insuranceId, documentType, file, progressCallback }) {
    return this._uploadInsuranceDocument('me', {
      insuranceId,
      documentType,
      file,
      progressCallback
    })
  }

  /**
   * Upload a document to the order's insurance
   * @param productStatusId - the id of the given product status (order)
   * @param insuranceId - which insurance the documentum belongs to
   * @param documentType - can be "front" or "back" (image of the insurance card)
   * @param file - File to upload
   * @param progressCallback - The progress of the File upload. (percentage) => {...}
   * @returns {AxiosPromise<any>}
   */
  static uploadOrderInsuranceDocument(
    productStatusId,
    { insuranceId, documentType, file, progressCallback }
  ) {
    return this._uploadInsuranceDocument(`productstatus/${productStatusId}`, {
      insuranceId,
      documentType,
      file,
      progressCallback
    })
  }

  static _uploadInsuranceDocument(prefix, { insuranceId, documentType, file, progressCallback }) {
    const data = {
      document: file,
      document_type: documentType
    }
    return postMultiPartFormData(
      data,
      `${prefix}/insurance/info/${insuranceId}/document/`,
      progressCallback
    )
  }

  /**
   * Delete the user's insurance document
   * @param insuranceId - which insurance the documentum belongs to
   * @param documentId - which doc to delete
   * @returns {AxiosPromise<any>}
   */
  static deleteUserInsuranceDocument({ insuranceId, documentId }) {
    return this._deleteInsuranceDocument('me', { insuranceId, documentId })
  }

  /**
   * Delete the order's insurance document
   * @param productStatusId - the id of the given product status (order)
   * @param insuranceId - which insurance the documentum belongs to
   * @param documentId - which doc to delete
   * @returns {AxiosPromise<any>}
   */
  static deleteOrderInsuranceDocument(productStatusId, data) {
    return this._deleteInsuranceDocument(`productstatus/${productStatusId}`, data)
  }

  static _deleteInsuranceDocument(prefix, { insuranceId, documentId }) {
    return axios.delete(`${prefix}/insurance/info/${insuranceId}/document/${documentId}`)
  }

  /**
   * Download the user's insurance document
   * @param document - which doc to downlood. Most important to have the doc id
   * @returns {AxiosPromise<any>}
   */
  static async downloadUserInsuranceDocument(document) {
    const data = await axios.get(`/me/insurance/document/${document.id}/download`, {
      responseType: 'arraybuffer'
    })

    return this._downloadData(data)
  }

  /**
   * Download the list of insurance companies
   * @returns {AxiosPromise<any>}
   */
  static getInsuranceCompany() {
    return axios.get('insurance/payers/').then(getResponse).catch(getErrorResponse)
  }

  /**
   * Mark the order checkout as "done by insurance"
   * @returns {AxiosPromise<any>}
   */
  static setInsuranceSelected(productStatusId) {
    return axios.post(`/productstatus/${productStatusId}/set_insurance_selected/`)
  }

  /**
   * Get the user's status
   * @param data
   * @returns {AxiosPromise<any>}
   */
  static getMyStatus() {
    return axios.get('me/status/')
  }

  /**
   * Register a kit
   * @param data
   * @returns {AxiosPromise<any>}
   */
  static registerKit(data) {
    return axios.post('kit/', data)
  }

  static getKit(params) {
    return axios.get('kit/', { params })
  }

  /**
   * Register a kit with an existing product status
   * @param productStatusId, data
   * @returns {AxiosPromise<any>}
   */
  static registerKitToProductStatus(productStatusId, data) {
    return axios.post(`/productstatus/${productStatusId}/register_kit/`, data)
  }

  /**
   * Validating kit id
   * @param data
   * @returns {AxiosPromise<any>}
   */
  static validateKit(data) {
    return axios.post('/kit/registration_validation/', data)
  }

  /**
   * Create product status
   * @param data
   * @returns {AxiosPromise<any>}
   */
  static createProductStatus(data) {
    return axios.post('/productstatus/', data)
  }

  /**
   * Get product status
   * @param params
   * @returns {AxiosPromise<any>}
   */
  static getProductStatus(params) {
    return axios.get('productstatus/', { params })
  }

  /**
   * Get Product Status by Id
   * @param id
   * @returns {AxiosPromise<any>}
   */
  static getProductStatusById(id) {
    return axios.get(`/productstatus/${id}/`)
  }

  static getConsentsByProductStatusId(id) {
    return axios.get(`/productstatus/${id}/consent/`)
  }

  /**
   * Get Workflow by product status Id
   * @param productStatusId
   * @returns {AxiosPromise<any>}
   */
  static getWorkflowByProductStatusId(productStatusId) {
    return axios.get(`/productstatus/${productStatusId}/workflow/`)
  }

  /**
   * Mark product status as completed
   * @param productStatusId
   * @returns {AxiosPromise<any>}
   */
  static markProductStatusCompleted(productStatusId) {
    return axios.post(`productstatus/${productStatusId}/mark_complete/`)
  }

  /**
   * Set Product was Consent and ready to see Result(s)
   * @param productStatusId
   * @returns {AxiosPromise<any>}
   */
  static viewedResult(productStatusId) {
    return axios.post(`productstatus/${productStatusId}/result_consent/`)
  }

  /**
   * Set Test Consent
   * @param productStatusId
   * @returns {AxiosPromise<any>}
   */
  static consentToTest(productStatusId) {
    return axios.post(`productstatus/${productStatusId}/set_test_consent/`)
  }

  /**
   * Send a PCP consent to share result
   * @param productStatusId
   * @returns {AxiosPromise<any>}
   */
  static consentToShareWithHCP(productStatusId) {
    return axios.post(`productstatus/${productStatusId}/set_pcp_consent/`)
  }

  /**
   * Get Purchases
   * @returns {AxiosPromise<any>}
   */
  static getPurchases() {
    return axios.get('purchase/?page_size=max')
  }

  /**
   * Get Surveys
   * @returns {AxiosPromise<any>}
   */
  static getSurveys(params) {
    return axios.get('questionnaire/survey/', { params })
  }

  /**
   * GET Survey by Id
   * @param id
   * @returns {AxiosPromise<any>}
   * GET /api/questionnaire/survey/{id}/
   */
  static getSurveyById(id) {
    return axios.get(`questionnaire/survey/${id}/`)
  }

  /**
   * GET Survey Existing Answers by Id
   * @param id
   * @returns {AxiosPromise<any>}
   * GET /api/questionnaire/survey/{survey_id}/answers/
   */
  static getSurveyAnswersById(id) {
    return axios.get(`questionnaire/survey/${id}/answers/`)
  }

  /**
   * Get Survey First Question by Id
   * @param id
   * @returns {AxiosPromise<any>}
   */
  static getSurveyNextQuestionById(id) {
    return axios.post(`questionnaire/survey/${id}/response/`)
  }

  /**
   * Get Survey existing responses by Id
   * @param id
   * @returns {AxiosPromise<any>}
   */
  static getSurveyResponsesById(id) {
    return axios.get(`questionnaire/survey/${id}/response/`)
  }

  /**
   * POST full survey answers
   * @param id
   * @param data
   * @returns {AxiosPromise<any>}
   */
  static sendFullSurveyResponses(id, data) {
    return axios.post(`questionnaire/survey/${id}/response_full/`, data)
  }

  /**
   * POST Survey answer by Id
   * @param id
   * @returns {AxiosPromise<any>}
   * /api/questionnaire/survey/{survey_id}/answers/
   */
  static sendSurveyAnswer(id, data) {
    return axios.post(`questionnaire/survey/${id}/answers/`, data)
  }

  /**
   * GET Survey questions by name
   * @returns {AxiosPromise<any>}
   * /api/questionnaire/{name}/
   */
  static getSurveyQuestionsByName(name) {
    return axios.get(`questionnaire/${name}/`)
  }

  /**
   * GET Products list the user is eligible for. Use only when the user is authenticated
   * @returns {AxiosPromise<any>}
   */
  static getMyEligibleProductList() {
    return axios.get('me/eligible_products/')
  }

  /**
   * GET Products list
   * @returns {AxiosPromise<any>}
   */
  static getProductList(params) {
    return axios.get('product/?page_size=max', { params })
  }

  /**
   * GET Product by ID
   * @param id
   * @returns {AxiosPromise<any>}
   */
  static async getProductById(id) {
    let response
    try {
      response = await axios.get(`product/${id}/`)
    } catch (err) {
      if (err.response?.status === 404) {
        response = await axios.get(`product/${id}/?active=0`)
      }
    }

    return response
  }

  /**
   * GET Vital signs for product
   *
   * @param productId
   * @returns {Promise<AxiosResponse<any>>}
   */
  static getProductVitalSigns(productId) {
    return axios.get(`product/${productId}/vital_signs/`)
  }

  /**
   * Get Organization by access code
   * @returns {AxiosPromise<any>}
   */
  static getOrganizationByAccessCode(accessCode) {
    return axios.get(`organization/check_access_code/?access_code=${accessCode}`)
  }

  /**
   * GET all lab orders (results)
   * @param productStatusId
   * @param observationId
   * @returns {AxiosPromise<any>}
   */
  static getLabOrder(productStatusId = null, observationId = null) {
    const params = {}
    if (productStatusId) {
      params.productstatus = productStatusId
    }
    if (observationId) {
      params.observation = observationId
    }
    return axios.get('laborder/', { params })
  }

  /**
   * GET all the panels and biomarkers that the user has ordered and has results for
   * @returns {AxiosPromise<any>}
   */
  static async getPanelsAndBiomarkers() {
    return axios
      .get('ordered_lab_items/released/?page_size=max')
      .then(getResponse)
      .catch(getErrorResponse)
  }

  static getObservation(params) {
    return axios.get('observation/', { params })
  }

  static getObservationDetails(biomarkerId, pageSize = 10) {
    const params = {
      biomarker: biomarkerId,
      page_size: pageSize,
      lab_result__approved_date__isnull: false
    }
    return axios.get('observation/', { params }).then(getResponse).catch(getErrorResponse)
  }

  static createObservation(data) {
    return axios.post('observation/', data)
  }

  /**
   * List the result activities for a single order
   *
   * @param productStatusId
   * @param filters
   * @param page
   * @param pageSize
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async getResultActivities(productStatusId, filters, page, pageSize) {
    const params = {
      page,
      page_size: pageSize,
      ...filters
    }

    const res = await axios.get(`productstatus/${productStatusId}/patient_event/`, { params })
    return res.data
  }

  // TODO: old labresult endpoint, might be deleted after migrating all platforms to use only 'laborder/':
  /**
   * GET all lab results
   * @param productStatusId
   * @returns {AxiosPromise<any>}
   */
  static getLabResults(productStatusId) {
    const params = {
      productstatus: productStatusId,
      is_active: true
    }
    return axios.get('labresult/', { params })
  }

  /**
   * GET lab results filtered by the biomarker of the connected labOrder
   * TODO: old labresult endpoint, might be deleted after migrating all platforms to use only 'laborder/':
   * @param biomarkerId
   * @param pageSize
   * @returns {AxiosPromise<any>}
   */
  static getLabResultsForLabOrderBiomarker(biomarkerId, pageSize = 10) {
    const params = {
      page_size: pageSize,
      lab_order_biomarker: biomarkerId,
      approved_date__isnull: false
    }
    return axios.get('labresult/', { params })
  }

  /**
   * Get the signed out lab result object
   * @param qrParam
   * @returns {Promise<AxiosResponse<any>>}
   */
  static getLabNoAuth(qrParam) {
    return axios.get(`no_auth_labresult/${qrParam}/`)
  }

  /**
   * Download PDF
   * @param assortment
   * @param id
   * @param isDownloadForView
   */
  static downloadPDF(assortment, id, isDownloadForView = false) {
    const operation = (assortment) => {
      if (assortment === 'result') {
        return axios.get(`labresult/${id}/download/`, {
          responseType: 'arraybuffer'
        })
      } else if (assortment === 'requisition') {
        return axios.get(`productstatus/${id}/download_requisition/`, {
          responseType: 'arraybuffer'
        })
      } else if (assortment === 'observation') {
        return axios.get(`observation/${id}/download/`, {
          responseType: 'arraybuffer'
        })
      } else if (assortment === 'order_confirmation') {
        return axios.get(`productstatus/${id}/?format=pdf`, {
          responseType: 'arraybuffer'
        })
      }
    }

    const download = (data) => this._downloadData(data, isDownloadForView)

    return operation(assortment).then(download)
  }

  static _downloadData(res, isDownloadForView) {
    // the following downloads the file as a blob and saves it
    const blob = new Blob([res.data], { type: res.headers['content-type'] })

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob)
      return
    }

    const url = window.URL.createObjectURL(blob)
    if (isDownloadForView) {
      return url
    }
    const link = document.createElement('a')
    link.href = url

    // set type based on content-disposition
    const contentDisposition = res.headers['content-disposition']
    let fileName = 'unknown'
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
      if (fileNameMatch?.length === 2) {
        fileName = fileNameMatch[1]
      } else {
        fileName = contentDisposition.split('filename="')[1]
      }
    }

    // download
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(url)
    }, 100)
  }

  /**
   * GET Document, like signup, terms and conditions, privacy policy, etc. by label
   * @param label
   * @returns {AxiosPromise<any>}
   */
  static getDocument(label) {
    return axios.get(`document/${label}/`)
  }

  /**
   * GET Product Document, like consent for product with product_id by label
   * @param productId
   * @param label
   * @returns {AxiosPromise<any>}
   */
  static getProductDocument(productId, label) {
    return axios.get(`product/${productId}/document/${label}/`)
  }

  static async getStripeCheckoutSession(data) {
    return axios.post('/store/stripe_checkout/', data)
  }

  static async getPayment(params) {
    return axios.get('/payment/', { params })
  }

  static async getSuccessCheckoutDetails(sessionId) {
    return axios.get(`/payment_by_session_id/${sessionId}/`)
  }

  /**
   * Get Guardian Consent data
   * @param uuid
   * @returns {AxiosPromise<any>}
   */
  static getGuardianConsent(uuid) {
    return axios.get(`/consent/${uuid}`)
  }

  /**
   * Set Guardian Consent
   * @param uuid
   * @returns {AxiosPromise<any>}
   */
  static setGuardianConsent(uuid) {
    return axios.post(`/consent/${uuid}/set_consent/`)
  }

  /**
   * Get User CTA Cards
   * @returns {AxiosPromise<any>}
   */

  static getUserCTACards() {
    return axios.get('/me/cards/')
  }

  static getTheme() {
    return axios.get('/theme/').then(getResponse).catch(getErrorResponse)
  }

  /**
   * Get feature flags data
   * @returns {AxiosPromise<any>}
   */
  static getFeatureFlags() {
    return axios.get('/flags/')
  }

  /**
   * Getting config data
   * @returns {Promise<any>}
   */
  static getConfigs() {
    return axios.get('config/')
  }

  static getIntegrationSettings() {
    return axios.get('integration_setting/')
  }

  /**
   * Ping the BE config API to see whether it is available or not
   * @returns {Promise<any>}
   */
  static pingConfig() {
    return axios.head('config/')
  }

  /**
   * Get Phlebotomy Appointments Availability
   */
  static getPhlebotomyAppointmentsAvailability(fromDate, patientId, productId) {
    return axios.get(
      `/services/mobile-phlebotomy/appointments/availability/`,

      {
        params: {
          from_date: fromDate,
          patient: patientId,
          product: productId
        }
      }
    )
  }

  /**
   * Create Phlebotomy Appointment
   */
  static async createPhlebotomyAppointment(appointment) {
    return axios.post(`/services/mobile-phlebotomy/appointments/`, appointment)
  }

  static async cancelPhlebotomyAppointment(id) {
    return axios.delete(`/services/mobile-phlebotomy/appointments/${id}/`)
  }

  /**
   * Get Quest locations
   */
  static async getQuestLocations(productStatusId, params = {}) {
    return axios.get('/services/psc/quest/locations/', {
      params: {
        productstatus: productStatusId,
        ...params
      }
    })
  }

  /**
   * Get Quest slots
   *
   * @param fromDate
   * @param productStatusId
   * @param siteCodes
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  static async getQuestSlots(fromDate, productStatusId, siteCodes) {
    const params = new URLSearchParams()
    params.append('from_date', fromDate)
    params.append('productstatus', productStatusId)
    siteCodes.forEach((siteCode) => params.append('site_codes', siteCode))

    return axios.get('/services/psc/quest/slots/', { params })
  }

  /**
   * Create Quest appointment
   *
   * @param appointment
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  static async createQuestAppointment(appointment) {
    return axios.post('/services/psc/quest/appointments/', appointment)
  }

  /**
   * Cancel Quest appointment
   *
   * @param id
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  static async cancelQuestAppointment(id) {
    return axios.delete(`/services/psc/quest/appointments/${id}/`)
  }

  /**
   * Validates a given address object to ensure city/state/zip match.
   * @param data
   * @returns {Promise<void>}
   */
  static async addressAndZipValidation(data) {
    const res = await axios.post(`/zip-check/`, data)
    return res.data
  }

  static async getZocdocAccessToken() {
    return axios.get('/services/psc/zocdoc/access-token/')
  }

  static async getZocdocReferenceData(productStatusId, params) {
    return axios.get(`/services/psc/zocdoc/reference-data/${productStatusId}/`, { params })
  }

  static async createZocdocAppointment(data) {
    return axios.post('/services/psc/zocdoc/appointments/', data)
  }

  static async cancelZocdocAppointment(id) {
    return axios.delete(`/services/psc/zocdoc/appointments/${id}/`)
  }

  static async eFaxResult(labResultId, healthCareProviderId) {
    return axios.post('/services/fax/send-results-pcp/', {
      lab_result_id: labResultId,
      healthcare_provider_id: healthCareProviderId
    })
  }

  static async emailResult(labResultId, healthCareProviderId) {
    return axios.post('/email-labresult-provider/', {
      lab_result_id: labResultId,
      healthcare_provider_id: healthCareProviderId
    })
  }
}
