// The default configs specified in this file are not necessarily required to be specified among
// the BE Frontend configs. But if they are defined in the BE Frontend configs, they will always
// override these defaultConfigs.
// If you delete or change any of these configs you must be sure each BE Frontend configs are
// correspondingly changed (if needed).
import { PREFIX } from '@/constants/document.js'
import { CONFIG } from '@/constants/config.js'

export default {
  [CONFIG.DATE_TIME_FORMAT]: {
    dateAndTime: 'MM/DD/YYYY hh:mm A',
    dateAndTimeWithWrittenMonth: 'MMMM DD, YYYY hh:mm A',
    dateAndTimeWithNameOfDay: 'dddd, MMMM DD YYYY, hh:mm A',
    dateTimeMinHoursWithAt: 'dddd, LL [at] hh:mm A',
    dateTimeMinHoursWithAtWithZone: 'dddd, LL [at] hh:mm A z',
    dateISO: 'YYYY-MM-DD',
    default: 'MMM DD YYYY',
    long: 'MMMM DD YYYY',
    short: 'MM/DD/YYYY'
  },

  [CONFIG.GENDERS]: [
    { text: 'form.gender.male', answer_id: 'male', id: 'male' },
    { text: 'form.gender.female', answer_id: 'female', id: 'female' },
    { text: 'form.gender.other', answer_id: 'other', id: 'other' },
    { text: 'form.gender.unknown', answer_id: 'unknown', id: 'unknown' }
  ],

  [CONFIG.GUARDIAN_RELATIONSHIPS]: [
    { text: 'form.guardian.relationship.parent', answer_id: 'parent', id: 'parent' },
    { text: 'form.guardian.relationship.guardian', answer_id: 'guardian', id: 'guardian' }
  ],

  [CONFIG.RACES]: [
    { text: 'form.race.american_indian', answer_id: 'american_indian', id: 'american_indian' },
    { text: 'form.race.asian', answer_id: 'asian', id: 'asian' },
    { text: 'form.race.black', answer_id: 'black', id: 'black' },
    { text: 'form.race.pacific_islander', answer_id: 'pacific_islander', id: 'pacific_islander' },
    { text: 'form.race.multiple', answer_id: 'multiple', id: 'multiple' },
    { text: 'form.race.white', answer_id: 'white', id: 'white' },
    { text: 'form.race.other', answer_id: 'other', id: 'other' },
    { text: 'form.race.no_answer', answer_id: 'no_answer', id: 'no_answer' }
  ],

  [CONFIG.ETHNICITIES]: [
    { text: 'form.ethnicity.hispanic_latino', answer_id: 'hispanic_latino', id: 'hispanic_latino' },
    {
      text: 'form.ethnicity.not_hispanic_latino',
      answer_id: 'not_hispanic_latino',
      id: 'not_hispanic_latino'
    },
    { text: 'form.ethnicity.other', answer_id: 'other', id: 'other' },
    { text: 'form.ethnicity.no_answer', answer_id: 'no_answer', id: 'no_answer' }
  ],

  [CONFIG.SHOW_SIGNUP_ACCESS_CODE]: true,
  [CONFIG.IDLE_TIMEOUT_MINUTES]: 15,

  [CONFIG.COMPANY_COUNTRY_ISO_CODE]: 'US',

  [CONFIG.PROFILE_AGE_LIMIT_TO_NOT_HAVE_GUARDIAN]: 18,
  [CONFIG.PRODUCT_STATUS_PAGE_SIZE]: 5,
  [CONFIG.IS_ADDRESS_IN_PRESCREEN_PROFILE]: true,

  [CONFIG.NAVBAR]: {
    logo: {
      signedOutRouterLinkTo: 'Home',
      footer: {
        href: 'https://ixlayer.com/'
      }
    },
    hasUserName: false
  },

  [CONFIG.ROUTER_FALLBACK]: {
    signedIn: {
      path: 'dashboard'
    },
    signedOut: {
      path: 'home',
      name: 'Home'
    }
  },

  [CONFIG.ICON_COLOR_V2]: {
    selectedMark: '#2ED5AD',
    virtual: '#2ED5AD',
    phone: '#2ED5AD',
    questionMark: '#FFFFFF',
    statusTracker: {
      default: '#C9C9C9',
      completed: '#FFFFFF',
      active: '#FFFFFF'
    }
  },

  [CONFIG.SCANDIT_LICENSE_KEY]:
    'Ab8EAxB7NxrRIQFd+w9PKYsZmx8ZGorByj0fS40tO8ipMJkZ+3duIpds3Ud2ansd7lkHNYtAWiiUaYhrj1b4KIEmaTakNPZlxWrOPbghPRm8cXo5p0warY5vqyB3QUxAP3EetL1jdJfkFWFZpVgjznxjMWBhVHQd83B4MTtBWpyOM6igESa6wHd3VktTXC7ksnWBT9sN7uG2aHOLWHZkKg4s/QBEYlzwxjWDqhNQ9dR0WhlEUkunJ0l40uJfcB4IJxqBkfJ/KVVQC2Em22e2LzFsggbUOhH1N3yiR6l6ZY79ZH7XSTfhjnYfgyZVUTOXZ3gKkBw8OSI+UaUgUHHBsjUMg8BBWVoeKh/r1shypbo9Kv43JRNAIUZ4g04PRLNRe2FYr4N7C1WXGcKZ2n9sLGcoOg9AamUHVWyOPSh/qc+VNONAm0jgV8UyJPFdZ8DLElPBYaABlUnSYgfvyFE0qmZLVX5ZQSGvcymGHDtPjQDwcx2nFWagqctAAPEIVKn73wGpLUdNPAIAKaH+nXFw+BRXxw58BWkbdHphbkNHn0FjbiAk4n9qePE/tJ8LcP12Dy5CJ9Z+c3X4Z0OaqGa5NxMYZUHdfMatIT+gEjpSZ1ayftFUWyBunf1uuO6VYhnSDUvu9clGYa2nCdJJFU+j2IhmZXxfSmob7HMHgK1CfGnxElfoMGVL4L8Grp1WR6v1Hl99HZYJ41B9fkjjUFdcA7RJBxAYbMAVojFntuNshQ82V9tMgWJ0rnZ+/T7PW/5ybjVIwoVsLiWtE6yaiXiUJMBf02tEPNFdcWPkfi5WQvr6ainlRlye6tkNHIOqXBwCWS3EGaFlJGw8are5i2BawmcRgSExVOpBqRtotyth9kJmRNVihiRzo2VlUP1fYNOdpUSmYmwwBD++L9tKxWQjS3VmOPdWchMwzF4XMUx1nY53MIHZtU9qmphNSH4ARCunpV53vwxaJfG6SENGDkpwVeh99c0jZW4D9VDGLlcyUuIic8cDLnaSWldxhKDxU3X/J3uaXL1SdlWub9wqlWPDLfhZbL9RPL71PUQSaNUE4JZrZp1NIiiv9rdxoibvXoSjlnD3OTFo5xkSZDiUTHa3rDMirR6UUNYCQX2QooJq2rJHXNzdT1tBIoAxoiOhdbBAZw3hLrd/fQ/gegvWSnsO5kch26dyeIlzf1pWTTd13kw0YZoefW9f9UtHoLBPTu+6Clvs+UgZrUVsSjl1hUzZxxlpjxtPLS81mAdIgJsNG9U7I6sTNmPvb9qlCuZ/sCwwgAdEc6ORynMKn7qAOkf05CyVkEmvdlT0DSeSNcJqUhf/+7QBIIAPrf7R9PBVdQb3pz+u52SKqLkjxT0u7JQygxxlEUyiHHkskSjEK0sDsXCzwBcrFzu8V1vEe/n8O5HLXhKr/9pcydD610ekGlm2dQu03gMbcBeeVfPOnyD7M8xcWB+ZbInKm8vY4TRaERGK7Kweu66hQh4duCbrGv3jVrLD0/zHh9KBeXAtSXjAxG/ovh7j8Cp6jESTXDY10OJQABBqW0P61/QogVIfB+jS9Te5vW09It/1pA3AGAo9TZvxluDNcKVIeNs3Kmb8bqR0cwlLUM+hSweYoBmkDJAtJP3GTgFwu/NeaOSQ/7NuJfGleqThIRPxr+a4M7zIAvF6/wAb1u9liUhJ2C3KeFGyDPPXb6CNm8CuTAMLwnUGQTVkr/pzvfLcYuc6G+leO7OPwxLEshaXthxg+cPOdY/+T/DTU9DRoYXGNPk2HWOIrzkRF2cKesEWK1ynIjVsbECCzrRLIN2pmcNw9aMTMAZyh+l1HXmGE/Sj7QwI/PX0HTig4Eu9QkpgrSwbZ65hbQoBN7PQA3lGW1ZJfmhVU3PY3zdhKQ86xJXlUEoBRSws0c22AEwk3KSmRehFyfiST4TX6lTZTzchk8MQNxOwrwp4djvfsQ==',

  [CONFIG.ACUITY_SCHEDULING_BASE_URL]: 'https://app.squarespacescheduling.com',

  // Use sessions instead of localStorage. Session data is cleared when a browser window is
  // closed vs localStorage which persists the data even after window is closed
  [CONFIG.USE_SESSION_STORAGE]: false,
  [CONFIG.USE_STATE_WORKFLOW_PROPERTY]: false,

  [CONFIG.DSAR_URL]: '',
  [CONFIG.FIND_A_SPECIALIST]: {},
  [CONFIG.GOOGLE_ANALYTICS_G4]: '',
  [CONFIG.HIDE_ACTIVATE_KIT_BUTTON_IN_HEADER]: false,
  [CONFIG.HIDE_START_HERE_BUTTON_IN_HEADER]: false,

  [CONFIG.LINKED_IN_PARTNER_ID]: '',
  [CONFIG.LINKED_IN_CONVERSIONS]: {},

  [CONFIG.MAIN_HEADER_PROFILE_PAGE_MENU_ITEMS_V3]: [],
  [CONFIG.MAIN_HEADER_SIGNED_OUT_MENU_ITEMS]: [],

  [CONFIG.SELECT_TEST_PAGE_CONTENT]: {},
  [CONFIG.STYLESHEET_LINKS]: [],

  [CONFIG.TERMLY_WEBSITE_UUID]: '',
  [CONFIG.TERMS_OF_SERVICE_DOCUMENT]: PREFIX.TERMS_LABEL,
  [CONFIG.PRIVACY_POLICY_DOCUMENT]: PREFIX.PRIVACY_POLICY_LABEL,
  [CONFIG.SMS_TERMS_OF_SERVICE_DOCUMENT]: PREFIX.SMS_TERMS_LABEL,

  [CONFIG.PASSWORD_POLICY_MIN_LENGTH]: 6,

  [CONFIG.WORKFLOW_STATE_TEXTS]: {}
}
