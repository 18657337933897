import { store } from '@/store/index.js'
import { Trans } from '@/plugins/Translation.js'
import { i18n } from '@/plugins/i18n.js'

// views
const KitActivateLanding = () => import('@/components/views/KitActivate/KitActivateLanding.vue')
const KitSelectionV3 = () =>
  import('@/components/views/KitRegistration/KitSelectionV3/KitSelectionV3.vue')
const HealthRecordV3 = () => import('@/components/views/HealthRecord/HealthRecord.vue')

export default [
  {
    path: 'activate',
    name: 'KitActivateLanding',
    component: KitActivateLanding,
    beforeEnter: (to, from, next) => {
      if (store.getters.isAuthenticated) {
        next()
        return
      }

      const lang = to.params.lang || Trans.getUserSupportedLang()

      if (store.getters.featureFlags.enableActivateKitSignup) {
        next({
          name: 'Register',
          params: { lang },
          query: {
            signed_out_kit_activation: true
          }
        })
        return
      }

      next({
        name: 'Login',
        params: { lang },
        query: {
          redirect_to: store.getters.featureFlags.kitIdAutoSelectsProduct
            ? 'kit-activation/activate-kit/test'
            : 'kit-selection/test',
          signed_out_kit_activation: true
        }
      })
    },
    meta: {
      title: i18n.t('headful_title.kit_activate'),
      requiresAuth: true,
      skipSessionPopup: true,
      useBeforeEnterWhenSignedOut: true
    }
  },
  {
    path: 'health-record',
    name: 'HealthRecord',
    component: HealthRecordV3,
    meta: {
      title: i18n.t('headful_title.health_records'),
      requiresAuth: true
    }
  },
  {
    path: 'kit-selection/:productType',
    name: 'KitSelectionV3',
    component: KitSelectionV3,
    props: true,
    meta: {
      title: i18n.t('headful_title.kit_selection'),
      requiresAuth: true,
      skipSessionPopup: true
    }
  }
]
