// Creation stage
export const WORKFLOW_STATE_CREATION = 'creation'
export const WORKFLOW_STATE_CREATED = 'created'

// Onboarding stage
export const WORKFLOW_STATE_ONBOARDING = 'onboarding'
export const WORKFLOW_STATE_KIT_REGISTERED = 'kit_registered'
export const WORKFLOW_STATE_TEST_CONSENT_SIGNED = 'test_consent_signed'
export const WORKFLOW_STATE_QUESTIONNAIRE_SUBMITTED = 'questionnaire_submitted'
export const WORKFLOW_STATE_ADDITIONAL_SCREENING_COMPLETED = 'additional_screening_completed'
export const WORKFLOW_STATE_APPOINTMENT_SCHEDULED = 'appointment_scheduled'
export const WORKFLOW_STATE_INSURANCE_SELECTED = 'insurance_selected'
export const WORKFLOW_STATE_INSURANCE_PURCHASE = 'insurance_purchase'
export const WORKFLOW_STATE_PURCHASED = 'purchased'

// Test approval stage
export const WORKFLOW_STATE_TEST_APPROVAL = 'test_approval'
export const WORKFLOW_STATE_LDT_SUBMITTED = 'ldt_submitted'
export const WORKFLOW_STATE_LDT_APPROVED = 'ldt_approved'

// Lab processing stage
export const WORKFLOW_STATE_LAB_PROCESSING = 'lab_processing'
export const WORKFLOW_STATE_KIT_RECEIVED = 'kit_received'
export const WORKFLOW_STATE_KIT_IN_LAB = 'kit_in_lab'
export const WORKFLOW_STATE_KIT_IN_ANALYSIS = 'kit_in_analysis'
export const WORKFLOW_STATE_PARTIAL_DATA_RECEIVED = 'partial_data_received'
export const WORKFLOW_STATE_COMPLETE_DATA_RECEIVED = 'complete_data_received'
export const WORKFLOW_STATE_DATA_RECEIVED = 'data_received'
export const WORKFLOW_STATE_DATA_READY = 'data_ready'
export const WORKFLOW_STATE_KIT_ERROR = 'kit_error'

// Resulting stage
export const WORKFLOW_STATE_RESULTING = 'resulting'
export const WORKFLOW_STATE_RESULT_READY = 'result_ready'
export const WORKFLOW_STATE_LDT_RESULTS_SENT = 'ldt_results_sent'

// Complete stage
export const WORKFLOW_STATE_COMPLETE = 'complete'
export const WORKFLOW_STATE_FULLY_RESULTED = 'fully_resulted'
export const WORKFLOW_STATE_RESULT_APPROVED = 'result_approved'
export const WORKFLOW_STATE_RESULT_AMENDED = 'result_amended'
export const WORKFLOW_STATE_RESULT_CORRECTED = 'result_corrected'

// Closed stage
export const WORKFLOW_STATE_CLOSED = 'closed'
export const WORKFLOW_STATE_LDT_REJECTED = 'ldt_rejected'
export const WORKFLOW_STATE_RESULT_REJECTED = 'result_rejected'
export const WORKFLOW_STATE_DEACTIVATED = 'deactivated'

// Everything else
export const WORKFLOW_STATE_FULFILLMENT_SHIPPED = 'fulfillment_shipped'
export const WORKFLOW_STATE_KIT_RESHIPPED = 'kit_reshipped'
export const WORKFLOW_STATE_CANCELLED = 'cancelled'
export const WORKFLOW_STATE_PURCHASE_CANCELLED = 'purchase_cancelled'
export const WORKFLOW_STATE_ACCOUNT_REVOKED = 'account_revoked'
export const WORKFLOW_STATE_ACCOUNT_CLOSED = 'account_closed'
export const WORKFLOW_STATE_KIT_REG_WITHOUT_CONSENT = 'kit_registered_without_consent'

// Workflow states listed here will not be presented on the Patient Portal
// and instead will show the previous state in the transition history
export const IGNORED_WORKFLOW_STATES = [WORKFLOW_STATE_ADDITIONAL_SCREENING_COMPLETED]

export const WORKFLOW_ICONS = Object.freeze({
  cancelled: {
    component: () => import('@/components/icons/IxIconDeactivated.vue'),
    params: { width: '24', height: '24', viewBox: '0 0 24 24' }
  },
  error: {
    component: () => import('@/components/icons/IxIconErrorExclamationMark.vue'),
    params: { width: '4', height: '15', viewBox: '0 0 4 15' }
  },
  inLab: {
    component: () => import('@/components/icons/IxIconKitInLab.vue'),
    params: { width: '15', height: '16', viewBox: '0 0 15 16' }
  },
  kitActivated: {
    component: () => import('@/components/icons/IxIconKitRegistered.vue'),
    params: { width: '20', height: '20', viewBox: '0 0 20 20' }
  },
  kitShipped: {
    component: () => import('@/components/icons/IxIconKitShipping.vue'),
    params: { width: '17', height: '14', viewBox: '0 0 17 14' }
  },
  resultProcessing: {
    component: () => import('@/components/icons/IxIconResultProcessing.vue'),
    params: { width: '11', height: '14', viewBox: '0 0 11 14' }
  },
  resultReady: {
    component: () => import('@/components/icons/IxIconResultReady.vue'),
    params: { width: '14', height: '14', viewBox: '0 0 14 14' }
  },
  inReview: {
    component: () => import('@/components/icons/IxIconEyeRegular.vue'),
    params: { width: '16', height: '12', viewBox: '0 0 16 12' }
  },
  checkmark: {
    component: () => import('@/components/icons/IxIconStatusChecked.vue'),
    params: { width: '16', height: '12', viewBox: '0 0 16 12' }
  },
  bloodDrop: {
    component: () => import('@/components/icons/IxIconBloodDrop.vue'),
    params: { width: '20', height: '20', viewBox: '0 0 48 48' }
  },
  dollar: {
    component: () => import('@/components/icons/IxIconDollar.vue'),
    params: { width: '10', height: '16', viewBox: '0 0 10 16' }
  },
  calendarCheck: {
    component: () => import('@/components/icons/IxIconCalendarCheck.vue'),
    params: { width: '18', height: '18', viewBox: '0 2 20 20' }
  }
})

export const WORKFLOW_STATES = new Map()
  // Creation stage
  .set(WORKFLOW_STATE_CREATION, {
    icon: WORKFLOW_ICONS.checkmark
  })
  .set(WORKFLOW_STATE_CREATED, {
    icon: WORKFLOW_ICONS.checkmark
  })

  // Onboarding stage
  .set(WORKFLOW_STATE_ONBOARDING, {
    icon: WORKFLOW_ICONS.kitActivated
  })
  .set(WORKFLOW_STATE_KIT_REGISTERED, {
    icon: WORKFLOW_ICONS.bloodDrop
  })
  .set(WORKFLOW_STATE_TEST_CONSENT_SIGNED, {
    icon: WORKFLOW_ICONS.checkmark
  })
  .set(WORKFLOW_STATE_QUESTIONNAIRE_SUBMITTED, {
    icon: WORKFLOW_ICONS.checkmark
  })
  .set(WORKFLOW_STATE_APPOINTMENT_SCHEDULED, {
    icon: WORKFLOW_ICONS.calendarCheck
  })
  .set(WORKFLOW_STATE_INSURANCE_SELECTED, {
    icon: WORKFLOW_ICONS.checkmark
  })
  .set(WORKFLOW_STATE_INSURANCE_PURCHASE, {
    icon: WORKFLOW_ICONS.checkmark
  })
  .set(WORKFLOW_STATE_PURCHASED, {
    icon: WORKFLOW_ICONS.checkmark
  })

  // Test approval stage
  .set(WORKFLOW_STATE_TEST_APPROVAL, {
    icon: WORKFLOW_ICONS.bloodDrop
  })
  .set(WORKFLOW_STATE_LDT_SUBMITTED, {
    icon: WORKFLOW_ICONS.bloodDrop
  })
  .set(WORKFLOW_STATE_LDT_APPROVED, {
    icon: WORKFLOW_ICONS.bloodDrop
  })

  // Lab processing stage
  .set(WORKFLOW_STATE_LAB_PROCESSING, {
    icon: WORKFLOW_ICONS.inLab
  })
  .set(WORKFLOW_STATE_KIT_RECEIVED, {
    icon: WORKFLOW_ICONS.inLab
  })
  .set(WORKFLOW_STATE_KIT_IN_LAB, {
    icon: WORKFLOW_ICONS.inLab
  })
  .set(WORKFLOW_STATE_KIT_IN_ANALYSIS, {
    icon: WORKFLOW_ICONS.inLab
  })
  .set(WORKFLOW_STATE_PARTIAL_DATA_RECEIVED, {
    icon: WORKFLOW_ICONS.inLab
  })
  .set(WORKFLOW_STATE_COMPLETE_DATA_RECEIVED, {
    icon: WORKFLOW_ICONS.inLab
  })
  .set(WORKFLOW_STATE_DATA_RECEIVED, {
    icon: WORKFLOW_ICONS.inLab
  })
  .set(WORKFLOW_STATE_DATA_READY, {
    icon: WORKFLOW_ICONS.inLab
  })
  .set(WORKFLOW_STATE_KIT_ERROR, {
    pillType: 'error',
    icon: WORKFLOW_ICONS.error
  })

  // Resulting stage
  .set(WORKFLOW_STATE_RESULTING, {
    icon: WORKFLOW_ICONS.resultProcessing
  })
  .set(WORKFLOW_STATE_RESULT_READY, {
    icon: WORKFLOW_ICONS.resultProcessing
  })
  .set(WORKFLOW_STATE_FULLY_RESULTED, {
    icon: WORKFLOW_ICONS.resultProcessing
  })
  .set(WORKFLOW_STATE_LDT_RESULTS_SENT, {
    icon: WORKFLOW_ICONS.bloodDrop
  })

  // Complete stage
  .set(WORKFLOW_STATE_COMPLETE, {
    pillType: 'success',
    icon: WORKFLOW_ICONS.resultReady
  })
  .set(WORKFLOW_STATE_RESULT_APPROVED, {
    pillType: 'success',
    icon: WORKFLOW_ICONS.resultReady
  })
  .set(WORKFLOW_STATE_RESULT_AMENDED, {
    pillType: 'success',
    icon: WORKFLOW_ICONS.resultReady
  })
  .set(WORKFLOW_STATE_RESULT_CORRECTED, {
    pillType: 'success',
    icon: WORKFLOW_ICONS.resultReady
  })

  // Closed stage
  .set(WORKFLOW_STATE_CLOSED, {
    pillType: 'error',
    icon: WORKFLOW_ICONS.error
  })
  .set(WORKFLOW_STATE_RESULT_REJECTED, {
    pillType: 'error',
    icon: WORKFLOW_ICONS.error
  })
  .set(WORKFLOW_STATE_LDT_REJECTED, {
    pillType: 'error',
    icon: WORKFLOW_ICONS.error
  })
  .set(WORKFLOW_STATE_DEACTIVATED, {
    pillType: 'cancelled',
    icon: WORKFLOW_ICONS.cancelled
  })

  // Everything else
  .set(WORKFLOW_STATE_FULFILLMENT_SHIPPED, {
    icon: WORKFLOW_ICONS.kitShipped
  })
  .set(WORKFLOW_STATE_KIT_RESHIPPED, {
    icon: WORKFLOW_ICONS.kitShipped
  })
  .set(WORKFLOW_STATE_CANCELLED, {
    pillType: 'cancelled',
    icon: WORKFLOW_ICONS.cancelled
  })
  .set(WORKFLOW_STATE_PURCHASE_CANCELLED, {
    pillType: 'cancelled',
    icon: WORKFLOW_ICONS.cancelled
  })
  .set(WORKFLOW_STATE_ACCOUNT_REVOKED, {
    pillType: 'error',
    icon: WORKFLOW_ICONS.error
  })
  .set(WORKFLOW_STATE_ACCOUNT_CLOSED, {
    pillType: 'error',
    icon: WORKFLOW_ICONS.error
  })
  .set(WORKFLOW_STATE_KIT_REG_WITHOUT_CONSENT, {
    icon: WORKFLOW_ICONS.kitActivated
  })
