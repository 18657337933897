import { i18n } from '@/plugins/i18n.js'
import { store } from '@/store/index.js'

// Dashboard
const DashboardRoot = () => import('@/components/views/Dashboard/Dashboard.vue')
const PreResultConsent = () =>
  import('@/components/views/Dashboard/PreResultConsent/PreResultConsent.vue')

export default {
  path: 'dashboard',
  component: DashboardRoot,
  children: [
    {
      path: '',
      name: 'TestDashboard',
      component: () => {
        return store.getters.featureFlags.pharmaPatientPortal
          ? import('@/components/views/PharmaDashboard/PharmaDashboard.vue')
          : import('@/components/views/Dashboard/DashboardV3.vue')
      },
      meta: { requiresAuth: true }
    },
    {
      // E-mail templates can still link to /dashboard/test and
      // without this redirect, they are sent to the home page instead of the login screen
      path: 'test',
      redirect: { name: 'TestDashboard' }
    },
    {
      path: ':productId/:productStatusId',
      name: 'PreResultConsent',
      component: PreResultConsent,
      props: true,
      meta: {
        title: i18n.t('headful_title.pre_result_consent'),
        requiresAuth: true
      }
    }
  ]
}
