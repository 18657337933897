import { i18n } from '@/plugins/i18n.js'
import { Trans } from '@/plugins/Translation.js'

import { store } from '@/store/index.js'
import { getFallbackPath } from '@/utils/routeUtils.js'

export const eligibilityWelcomePage = {
  path: 'eligibility/welcome',
  name: 'eligibilityWelcome',
  component: () => import('@/components/views/Eligibility/EligibilityWelcome.vue'),
  beforeEnter: async (to, from, next) => {
    if (store.getters.featureFlags.useEligibility) {
      return next()
    }
    return next(`${Trans.defaultLanguage}/${getFallbackPath()}`)
  },
  meta: {
    title: i18n.t('headful_title.eligibility_welcome'),
    isFooterHidden: true
  }
}

export default {
  path: 'eligibility',
  name: 'EligibilityCheckRoot',
  component: () => import('@/components/views/Eligibility/EligibilityCheckRoot.vue'),
  beforeEnter: async (to, from, next) => {
    if (store.getters.featureFlags.useEligibility) {
      return next()
    }
    return next(`${Trans.defaultLanguage}/${getFallbackPath()}`)
  },
  meta: {
    title: i18n.t('headful_title.eligibility_welcome')
  },
  redirect: { name: 'eligibilityWelcome' },
  children: [
    {
      path: 'check',
      name: 'EligibilityCheckIdentity',
      component: () => import('@/components/views/Eligibility/EligibilityCheckIdentity.vue'),
      props: (route) => ({ ...route.query, ...route.params }),
      meta: {
        title: i18n.t('headful_title.eligibility_check'),
        isFooterHidden: true
      }
    },
    {
      path: 'account',
      name: 'EligibilityAccountActivate',
      component: () => import('@/components/views/Eligibility/EligibilityCheckAccountActivate.vue'),
      props: (route) => ({ ...route.query, ...route.params }),
      meta: {
        title: i18n.t('headful_title.eligibility_check'),
        isFooterHidden: true
      }
    },
    {
      path: 'login',
      name: 'EligibilityLogin',
      component: () => import('@/components/views/Eligibility/EligibilityCheckLogin.vue'),
      meta: {
        title: i18n.t('headful_title.eligibility_check'),
        isFooterHidden: true
      }
    }
  ]
}
