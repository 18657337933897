// Globally register all `_base`-prefixed components
import './components/_globals.js'

import Vue from 'vue'
import axios from 'axios'
import { store } from '@/store/index.js'
import { GET_CONFIGS } from '@/store/config.js'
import { GET_FEATURE_FLAGS } from '@/store/feature.js'
import {
  IS_AUTHENTICATED,
  AUTH_TOKEN_NOT_FOUND_OR_INVALID,
  IS_PROFILE_COMPLETE,
  AUTH_IDLE_TIMEOUT
} from '@/constants/profile.js'
import { HAS_CONNECTION_ERROR, SET_INVALID_REFERRER } from '@/store/notifications.js'
import { GET_THEME } from '@/store/theme.js'
import VueSkipTo from '@vue-a11y/skip-to'
import { BaseService } from '@/classes/BaseService.js'
import { BREAKPOINTS } from '@/constants/breakpoints.js'

import App from './App.vue'
import router from './router/index.js'

import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import vueHeadful from 'vue-simple-headful'
import Toasted from 'vue-toasted'
import VueScrollTo from 'vue-scrollto'
import VueMq from 'vue-mq'

import * as Sentry from '@sentry/vue'

import { i18n } from '@/plugins/i18n.js'
import { Trans } from './plugins/Translation.js'
import common from '@/classes/common.js'
import { mapGetters } from 'vuex'
import { CONFIG } from '@/constants/config.js'
import { isEmpty } from 'lodash-es'
import { installGA4 } from '@/utils/analytics/setup.js'
import AutoLogoutService from '@/classes/AutoLogoutService.js'
import { trackOutboundLink } from '@/utils/analytics/events.js'
import { logToSentry, SENTRY_LOG_LEVEL } from '@/utils/logging.js'
import { FETCH_INTEGRATION_SETTINGS } from '@/store/integrationSetting.js'
import { installLinkedInInsightTag } from '@/utils/linkedin/setup.js'
import { installTermlyTag } from '@/utils/termly/setup.js'

Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans)

if (import.meta.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    Vue,
    dsn: import.meta.env.VUE_APP_SENTRY_DSN,
    release: `patient-portal@${__APP_VERSION__}`,
    environment: import.meta.env.VUE_APP_ENV
  })
}

// Design framework
Vue.use(BootstrapVue)
Vue.prototype.$eventBus = new Vue()
Vue.use(VueScrollTo)
Vue.use(VueSkipTo)

// validation
Vue.use(Vuelidate)

// Headful plugin for the SEO
Vue.use(vueHeadful)

Vue.use(Toasted, {
  theme: 'bubble',
  position: 'top-center',
  duration: 5000,
  className: 'ix-toaster-message'
})

Vue.use(VueMq, {
  breakpoints: BREAKPOINTS
})

BaseService.setupService(store, router)

let logout = null

const setupLogoutService = (getConfig) => {
  if (logout) {
    logout.stopInterval()
  }

  // the first parameter we specify our store and the second the amount of time for auto logout inactive users
  logout = new AutoLogoutService(store, getConfig(CONFIG.IDLE_TIMEOUT_MINUTES), () => {
    document.querySelector('body').classList.add('locked')
    store.commit(AUTH_IDLE_TIMEOUT, true)
  })
  logout.initInterval()
}

const setupInterceptors = (store) => {
  axios.interceptors.response.use(undefined, function (err) {
    return new Promise(function () {
      // if problem connecting
      if (!err.response) {
        store.dispatch(HAS_CONNECTION_ERROR, true)
        throw err
      } else if (err.response.status === 403) {
        store.dispatch(AUTH_TOKEN_NOT_FOUND_OR_INVALID)
        throw err
      } else if (err.response.status === 401) {
        throw err
      } else if (
        (err.response.status === 400 && /referr?er/i.test(err.response?.data?.message)) ||
        err.response.status === 503
      ) {
        store.dispatch(SET_INVALID_REFERRER, true)
        throw err
      } else if (err.response.status === 500) {
        Vue.toasted.show('Server error')
        throw err
      } else {
        throw err
      }
    })
  })
}

setupInterceptors(store)

const handleAuthenticationStateChange = ({ key, oldValue, newValue }, currentRoute) => {
  if (
    // Invalidate session when user logs out
    (key === IS_AUTHENTICATED && oldValue && !newValue && store.getters.isAuthenticated) ||
    // Redirect to Dashboard on profile completion
    (key === IS_PROFILE_COMPLETE &&
      oldValue === 'false' &&
      newValue === 'true' &&
      currentRoute?.meta?.redirectOnAuthentication)
  ) {
    window.location.reload()
  }
}

function outboundLinkTracker(event) {
  if (
    event.target.tagName !== 'A' ||
    event.target.getAttribute('data-exclude-from-global-outbound-click-tracking') !== null ||
    event.defaultPrevented
  ) {
    return
  }

  trackOutboundLink(event.target)
}

const app = new Vue({
  router,
  store,
  i18n,

  computed: {
    ...mapGetters(['getConfig', 'getThemeValue', 'isInvalidReferrer', 'userId']),
    favicon() {
      return common.cleanCssThemeUrl(this.getThemeValue('--favicon'))
    }
  },
  async mounted() {
    await this.setupAppStyles()
    if (isEmpty(store.getters.configs) && !store.getters.configsRequestFailed) {
      await this.$store.dispatch(GET_CONFIGS)
    }

    if (
      isEmpty(store.getters.integrationSettings) &&
      !store.getters.integrationSettingsRequestFailed
    ) {
      await this.$store.dispatch(FETCH_INTEGRATION_SETTINGS)
    }

    const isCookieConsentEnabled = this.getConfig(CONFIG.TERMLY_WEBSITE_UUID) !== ''
    if (isCookieConsentEnabled) {
      installTermlyTag(this.getConfig(CONFIG.TERMLY_WEBSITE_UUID))
    }

    let stylesheetLinks = this.getConfig(CONFIG.STYLESHEET_LINKS)
    if (stylesheetLinks && stylesheetLinks.length > 0) {
      stylesheetLinks.forEach((link) => {
        const el = document.createElement('link')
        el.rel = 'stylesheet'
        el.href = link
        document.head.appendChild(el)
      })
    }

    if (this.getConfig(CONFIG.GOOGLE_ANALYTICS_G4)) {
      installGA4(
        this.getConfig(CONFIG.GOOGLE_ANALYTICS_G4),
        this.$route,
        this.$store,
        this.userId,
        isCookieConsentEnabled
      )
    }

    if (this.getConfig(CONFIG.LINKED_IN_PARTNER_ID)) {
      installLinkedInInsightTag(this.getConfig(CONFIG.LINKED_IN_PARTNER_ID), isCookieConsentEnabled)
    }

    setupLogoutService(this.getConfig)

    const lang = Trans.getUserSupportedLang()
    Trans.setI18nLanguageInServices(lang)
    window.addEventListener('storage', (event) =>
      handleAuthenticationStateChange(event, this.$route)
    )
    if (this.favicon) {
      document.getElementById('favicon').href = this.favicon
    }

    document.documentElement.addEventListener('click', outboundLinkTracker)
  },

  destroyed() {
    window.removeEventListener('storage', (event) =>
      handleAuthenticationStateChange(event, this.$route)
    )
    document.documentElement.removeEventListener('click', outboundLinkTracker)
  },

  methods: {
    async setupAppStyles() {
      try {
        if (isEmpty(store.getters.featureFlags) && !store.getters.featureFlagsRequestFailed) {
          await store.dispatch(GET_FEATURE_FLAGS)
        }
        await this.$store.dispatch(GET_THEME)

        if (store.getters?.featureFlags?.pharmaPatientPortal) {
          document.body.classList.add('pharma-patient-portal')
        }
      } catch (e) {
        // Not sure if we want to display a toast here. Leaving as is for now.
      }
    }
  },

  render: (h) => h(App)
})

router.onReady(
  () => {
    app.$mount('#app')
  },
  (error) => {
    logToSentry({
      message: `FAILED TO COMPLETE INITIAL NAVIGATION: ${error}`,
      level: SENTRY_LOG_LEVEL.ERROR
    })
  }
)
